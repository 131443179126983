import { DictionaryService } from '@alliance/jobseeker/api'
import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { combineLatest, Observable, of, zip } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'
import { BreadcrumbList } from 'schema-dts'
import { VacancyListModel } from '../../openapi/model/vacancy-list.model'
import { HelpersService } from '../helpers.service'
import { Translations } from '../localization/translations'
import { PartialSeoParamsResponse, PlatformHosts, VacancyListSeoParams } from '../models'
/* eslint-disable */
@Injectable({ providedIn: 'root' })
export class VacancyListDictionaryService {
  public constructor(private translations: Translations, private translationService: TranslationService, private dictionaryService: DictionaryService, private helpersService: HelpersService) {}

  public getParams({ params }: VacancyListSeoParams): Observable<PartialSeoParamsResponse> {
    const { keywords, totalItems, isNarrowedSearch, cityId, district, metro } = params
    return this.getBaseParams$(params).pipe(
      switchMap(baseParams =>
        of(keywords).pipe(
          switchMap(value => (value ? this.dictionaryService.getKeywordsWithTransliteration() : of([]))),
          switchMap(list => {
            const isKeywordsNotAllowedForIndexing = list.length && !list.some(item => (item?.name ?? '').toLowerCase() === keywords.toLowerCase())
            if (
              params.isIndexingPageWithRubric ||
              // seo url exp experiment
              this.isTestPatch(keywords, cityId, '1', district) ||
              this.isTestPatch(keywords, cityId, '1-9', metro) ||
              this.isTestPatch(keywords, cityId, 'darnytskyi', district) ||
              this.isTestPatch(keywords, cityId, 'beresteiska', metro) ||
              this.isTestPatch(keywords, cityId, 'sviatoshynskyi', district) ||
              this.isTestPatch(keywords, cityId, 'vydubychi', metro) ||
              this.isTestPatch(keywords, cityId, 'obolonskyi', district) ||
              this.isTestPatch(keywords, cityId, 'boryspilska', metro)
            ) {
              return this.getIndexingParams$(params).pipe(map(indexingParams => ({ ...baseParams, ...indexingParams })))
            }
            return isKeywordsNotAllowedForIndexing || isNarrowedSearch || !totalItems
              ? of({ ...baseParams, noIndexNoFollow: true })
              : this.getIndexingParams$(params).pipe(map(indexingParams => ({ ...baseParams, ...indexingParams })))
          })
        )
      )
    )
  }

  private getJsonLd$(inputParams: VacancyListModel): Observable<string> {
    const { cityId, keywords } = inputParams
    const platform = PlatformHosts.desktop

    // only city
    if (cityId > 0 && !keywords) {
      return this.jsonLdOnlyCity(cityId, platform)
    }

    // only keywords
    if (!cityId && keywords) {
      return this.jsonLdOnlyKeywords(platform, keywords)
    }

    // keywords and city
    if (keywords && cityId > 0) {
      return this.jsonLdKeywordsCity(cityId, keywords, platform)
    }

    return of(this.helpersService.createJsonLd(platform, {})) // default
  }

  private jsonLdOnlyCity(cityId: number, platform: PlatformHosts): Observable<string> {
    return this.dictionaryService.getCityName$(cityId, null, true).pipe(
      map(cityName =>
        this.helpersService.createJsonLd<BreadcrumbList>(platform, {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            this.helpersService.getHomePageBreadcrumb(platform),
            this.helpersService.getSearchByVacanciesBreadcrumb(platform),
            {
              '@type': 'ListItem',
              position: 3,
              name: `${this.translationService.translate(this.translations.jsonLd.breadcrumbs.job)} ${this.translationService.translate(this.translations.jsonLd.breadcrumbs.inCity, {
                cityInflected: cityName
              })}`
            }
          ]
        })
      )
    )
  }

  private jsonLdOnlyKeywords(platform: PlatformHosts, keywords: string): Observable<string> {
    return of(
      this.helpersService.createJsonLd<BreadcrumbList>(platform, {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          this.helpersService.getHomePageBreadcrumb(platform),
          this.helpersService.getSearchByVacanciesBreadcrumb(platform),
          {
            '@type': 'ListItem',
            position: 3,
            name: `${this.translationService.translate(this.translations.jsonLd.breadcrumbs.job)} ${keywords}`
          }
        ]
      })
    )
  }

  private jsonLdKeywordsCity(cityId: number, keywords: string, platform: PlatformHosts): Observable<string> {
    return zip(
      this.dictionaryService.getCityName$(cityId, null, true),
      this.helpersService.createDesktopVacancyListUrl$(cityId, ''),
      this.helpersService.createDesktopVacancyListUrl$(0, keywords)
    ).pipe(
      map(([cityName, cityVacancyListDesktopUrl, searchTagUkraineVacancyListDesktopUrl]) => {
        const inCityTranslation = this.translationService.translate(this.translations.jsonLd.breadcrumbs.inCity, {
          cityInflected: cityName
        })

        return this.helpersService.createJsonLd<BreadcrumbList>(platform, {
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            this.helpersService.getHomePageBreadcrumb(platform),
            this.helpersService.getSearchByVacanciesBreadcrumb(platform),
            {
              '@type': 'ListItem',
              position: 3,
              item: cityVacancyListDesktopUrl,
              name: `${this.translationService.translate(this.translations.jsonLd.breadcrumbs.vacancies)} ${inCityTranslation}`
            },
            {
              '@type': 'ListItem',
              position: 4,
              item: searchTagUkraineVacancyListDesktopUrl,
              name: keywords
            },
            {
              '@type': 'ListItem',
              position: 5,
              name: `${keywords} ${inCityTranslation}`
            }
          ]
        })
      })
    )
  }

  private prependPageNumberText(page: number, text: string): string {
    return page > 1 ? `${text} ${this.translationService.translate(this.translations.vacancyList.pageLeadingText, { page })}` : text
  }

  // eslint-disable-next-line max-lines-per-function
  private getBaseParams$(inputParams: VacancyListModel): Observable<PartialSeoParamsResponse> {
    const { page, cityId, keywords, totalItems, isNarrowedSearch, rubricId, district, metro } = inputParams

    return combineLatest([this.dictionaryService.getCityName$(cityId, null, true), this.getRubricName(rubricId)]).pipe(
      // eslint-disable-next-line max-lines-per-function
      map(([cityName, rubricName]) => {
        cityName = cityId ? cityName : this.translationService.translate(this.translations.vacancyList.ukraine)

        const h1 = [this.translationService.translate(this.translations.vacancyList.h1, { keywords: keywords || null, cityName })]

        if (rubricName && !keywords) {
          return this.getBaeParamsRubricName(h1, cityName, totalItems, rubricName)
        }
        // seo url exp experiment
        if (this.isTestPatch(keywords, cityId, '1', district)) {
          return this.getTestBaseDistrictParams(totalItems, 'Голосіївському', 'Голосеевском')
        }
        if (this.isTestPatch(keywords, cityId, '1-9', metro)) {
          return this.getTestBaseMetroParams(totalItems, 'Академмістечко', 'Академгородок')
        }

        if (this.isTestPatch(keywords, cityId, 'darnytskyi', district)) {
          return this.getTestBaseDistrictParams(totalItems, 'Дарницькому', 'Дарницком')
        }
        if (this.isTestPatch(keywords, cityId, 'beresteiska', metro)) {
          return this.getTestBaseMetroParams(totalItems, 'Берестейська', 'Берестейская')
        }

        if (this.isTestPatch(keywords, cityId, 'sviatoshynskyi', district)) {
          return this.getTestBaseDistrictParams(totalItems, 'Святошинському', 'Святошинском')
        }
        if (this.isTestPatch(keywords, cityId, 'vydubychi', metro)) {
          return this.getTestBaseMetroParams(totalItems, 'Видубичі', 'Выдубичи')
        }

        if (this.isTestPatch(keywords, cityId, 'boryspilska', metro)) {
          return this.getTestBaseMetroParams(totalItems, 'Бориспільська', 'Бориспольская')
        }
        if (this.isTestPatch(keywords, cityId, 'obolonskyi', district)) {
          return this.getTestBaseDistrictParams(totalItems, 'Оболонському', 'Оболонском')
        }
        // end seo url exp experiment
        if (isNarrowedSearch || !totalItems) {
          return {
            h1,
            title: this.translationService.translate(this.translations.vacancyList.default.title),
            description: this.translationService.translate(this.translations.vacancyList.default.description, {
              totalItems
            })
          }
        }

        if (!keywords && cityId) {
          return {
            h1,
            title: this.translationService.translate(this.translations.vacancyList.onlyCity.title, { cityName }),
            description: this.translationService.translate(this.translations.vacancyList.onlyCity.description, {
              cityName,
              totalItems
            })
          }
        }

        return {
          h1,
          title: this.translationService.translate(this.translations.vacancyList.withKeywords.title, {
            cityName,
            keywords: keywords || null
          }),
          description: this.translationService.translate(this.translations.vacancyList.withKeywords.description, {
            cityName,
            keywords: keywords || null,
            totalItems
          })
        }
      }),
      map(params => ({
        ...params,
        title: this.prependPageNumberText(page, params.title),
        description: this.prependPageNumberText(page, params.description)
      }))
    )
  }

  private getBaeParamsRubricName(h1: string[], cityName: string, totalItems: number, rubricName: string): { h1: string[]; title: string; description: string } {
    return {
      h1,
      title: this.translationService.translate(this.translations.vacancyList.withRubric.title, {
        cityName,
        rubricName
      }),
      description: this.translationService.translate(this.translations.vacancyList.withRubric.description, {
        cityName,
        totalItems,
        rubricName
      })
    }
  }

  private getIndexingParams$(inputParams: VacancyListModel): Observable<PartialSeoParamsResponse> {
    const { cityId, keywords, page, metro, district } = inputParams

    return zip(
      this.helpersService.createDesktopVacancyListUrl$(cityId, keywords, page > 1 ? page : 0),
      this.helpersService.createDesktopVacancyListPathName$(cityId, keywords, page > 1 ? page : 0),
      this.getJsonLd$(inputParams)
    ).pipe(
      map(([desktopFullUrl, desktopPathName, jsonLd]) => {
        let desktopUrl = desktopFullUrl
        let desktopPath = desktopPathName

        if (inputParams.rubricId) {
          desktopUrl = this.getPathByRubric(desktopFullUrl, inputParams.rubricId)
          desktopPath = this.getPathByRubric(desktopPathName, inputParams.rubricId)
        }
        // seo url exp experiment
        if (this.isTestPatch(keywords, cityId, 'darnytskyi', district) || this.isTestPatch(keywords, cityId, '1', district)) {
          desktopUrl = this.getPathByDistrict(desktopFullUrl, district)
          desktopPath = this.getPathByDistrict(desktopPath, district)
        }

        if (this.isTestPatch(keywords, cityId, '1-9', metro) || this.isTestPatch(keywords, cityId, 'beresteiska', metro)) {
          desktopUrl = this.getPathByMetro(desktopFullUrl, metro)
          desktopPath = this.getPathByMetro(desktopPath, metro)
        }

        if (this.isTestPatch(keywords, cityId, 'sviatoshynskyi', district)) {
          desktopUrl = this.getPathByParams(desktopFullUrl, district)
          desktopPath = this.getPathByParams(desktopPath, district)
        }

        if (this.isTestPatch(keywords, cityId, 'vydubychi', metro)) {
          desktopUrl = this.getPathByParams(desktopFullUrl, metro)
          desktopPath = this.getPathByParams(desktopPath, metro)
        }

        if (this.isTestPatch(keywords, cityId, 'boryspilska', metro)) {
          desktopUrl = this.getPathByParams(desktopFullUrl, `metroBranches=${metro}`)
          desktopPath = this.getPathByParams(desktopPath, `metroBranches=${metro}`)
        }
        if (this.isTestPatch(keywords, cityId, 'obolonskyi', district)) {
          desktopUrl = this.getPathByParams(desktopFullUrl, `districtIds=${district}`)
          desktopPath = this.getPathByParams(desktopPath, `districtIds=${district}`)
        }
        // end seo url exp experiment
        return {
          canonicalUrl: desktopUrl,
          hrefLang: this.helpersService.createHrefLangURLs(desktopPath, desktopPath),
          jsonLd: {
            desktop: jsonLd,
            mobile: jsonLd
          }
        }
      })
    )
  }

  private getRubricName(rubricId: string | undefined): Observable<string | null> {
    if (!rubricId) {
      return of(null)
    }
    return this.dictionaryService.getRubricName$(+rubricId).pipe(take(1))
  }

  private getPathByRubric(path: string, rubric: string | undefined): string {
    if (!rubric) {
      return path
    }
    return path.includes('?') ? `${path}&rubrics=${rubric}` : `${path}?rubrics=${rubric}`
  }

  // seo url exp experiment
  private getPathByDistrict(path: string, district?: string): string {
    if (!district) {
      return path
    }
    return path.includes('?') ? `${path}&districtIds=${district}` : `${path}?districtIds=${district}`
  }

  private getPathByMetro(path: string, metro?: string): string {
    if (!metro) {
      return path
    }
    return path.includes('?') ? `${path}&metroBranches=${metro}` : `${path}?metroBranches=${metro}`
  }

  private getPathByParams(path: string, value?: string): string {
    if (!value) {
      return path
    }
    return `${path}/${value}`
  }

  private isTestPatch(keywords: string, cityId: number, targetValue: string, value?: string): boolean {
    return value === targetValue && keywords === 'водій' && cityId === 1
  }

  private getTestBaseDistrictParams(totalItems: number, valueUa: string, valueRu: string): { h1: string[]; title: string; description: string } {
    return {
      h1: [
        this.translationService.translate(this.translations.districtPathTest.h1, {
          district: this.translationService.currentLangIsUkrainian() ? valueUa : valueRu
        })
      ],
      title: this.translationService.translate(this.translations.districtPathTest.title, {
        district: this.translationService.currentLangIsUkrainian() ? valueUa : valueRu
      }),
      description: this.translationService.translate(this.translations.districtPathTest.desc, {
        totalItems,
        district: this.translationService.currentLangIsUkrainian() ? valueUa : valueRu
      })
    }
  }

  private getTestBaseMetroParams(totalItems: number, valueUa: string, valueRu: string): { h1: string[]; title: string; description: string } {
    return {
      h1: [
        this.translationService.translate(this.translations.metroPathTest.h1, {
          metro: this.translationService.currentLangIsUkrainian() ? valueUa : valueRu
        })
      ],
      title: this.translationService.translate(this.translations.metroPathTest.title, {
        metro: this.translationService.currentLangIsUkrainian() ? valueUa : valueRu
      }),
      description: this.translationService.translate(this.translations.metroPathTest.desc, {
        totalItems,
        metro: this.translationService.currentLangIsUkrainian() ? valueUa : valueRu
      })
    }
  }
}
