/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { StrictHttpResponse } from '../../strict-http-response'
import { RequestBuilder } from '../../request-builder'

import { TopTagsResponse } from '../../models/top-tags-response'

export interface TopSkillsByNameEndpointCvNameGet_1$Params {
  tag: string
  limit?: number
  'accept-language'?: string
}

export function topSkillsByNameEndpointCvNameGet_1(
  http: HttpClient,
  rootUrl: string,
  params: TopSkillsByNameEndpointCvNameGet_1$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<TopTagsResponse>> {
  const rb = new RequestBuilder(rootUrl, topSkillsByNameEndpointCvNameGet_1.PATH, 'get')
  if (params) {
    rb.query('tag', params.tag, {})
    rb.query('limit', params.limit, {})
    rb.header('accept-language', params['accept-language'], {})
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TopTagsResponse>
    })
  )
}

topSkillsByNameEndpointCvNameGet_1.PATH = '/CV/name'
