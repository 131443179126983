export const StreamTypeMap: Readonly<Record<StreamType, MessageTargetType[]>> = {
  chat: ['message', 'messageupdate'],
  notification: ['notification', 'Crm20Reminder', 'Crm20Event', 'Crm20CompanyRequest'],
  employerStateChanged: ['employeeStateChanged'],
  employerScopesChanged: ['employeeScopesChanged'],
  seekerNotification: ['seekerNotification'],
  employerNotification: ['employerNotification'],
  bannerStateChanged: ['bannerStateChanged'],
  bannerEmployerMessage: ['bannerEmployerMessage']
}

export type MessageTargetType =
  | 'message'
  | 'messageupdate'
  | 'notification'
  | 'Crm20Reminder'
  | 'Crm20Event'
  | 'Crm20CompanyRequest'
  | 'employeeScopesChanged'
  | 'employeeStateChanged'
  | 'seekerNotification'
  | 'employerNotification'
  | 'bannerStateChanged'
  | 'bannerEmployerMessage'

export type StreamType = 'chat' | 'notification' | 'employerStateChanged' | 'employerScopesChanged' | 'seekerNotification' | 'employerNotification' | 'bannerStateChanged' | 'bannerEmployerMessage'
