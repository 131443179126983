import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { WidgetRenderService } from './widget/widget-render.service'
import { Route, RouterModule, ROUTES } from '@angular/router'

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [RouterModule],
  providers: [
    WidgetRenderService,
    {
      provide: ROUTES,
      useFactory: (widgetRenderService: WidgetRenderService): Route[] => {
        const { isEmployer, isSeeker, chatExperiment } = widgetRenderService.get()
        if (isEmployer && chatExperiment) {
          return [
            {
              path: '',
              loadChildren: (): Promise<unknown> => import('@alliance/chat/shells/employer/mobile').then(m => m.ChatShellsEmployerMobileModule)
            }
          ]
        }
        if (isSeeker && chatExperiment) {
          return [
            {
              path: '',
              loadChildren: (): Promise<unknown> => import('@alliance/chat/shells/seeker/mobile').then(m => m.ChatShellsSeekerMobileModule)
            }
          ]
        }
        if ((isSeeker || isEmployer) && !chatExperiment) {
          return [
            {
              path: '',
              loadChildren: (): Promise<unknown> => import('@alliance/chat/shell-mobile').then(m => m.ChatShellMobileModule)
            }
          ]
        }
        return []
      },
      multi: true,
      deps: [WidgetRenderService]
    }
  ]
})
export class ChatShellDesktopAppRoutingModule {}
