import { ICONS_ASSETS_PATH } from '@alliance/shared/constants'
import { log } from '@alliance/shared/logger'
import { NgZoneHelperService } from '@alliance/shared/utils'
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser'
import { LOCATION } from '@ng-web-apis/common'
import { catchError, Observable, of, shareReplay, take } from 'rxjs'
import { IconNameSeparator } from '../autogenerated/constants/icon-name-separator'
import { IconName } from '../autogenerated/models/icon-model'

// https://github.com/angular/angular-cli/issues/2276#issuecomment-264502520
// global variable for webpack, has url for assets
declare let __webpack_public_path__: string

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  private registry: Map<IconName, Observable<string>> = new Map()

  public constructor(private http: HttpClient, @Inject(LOCATION) private location: Location, private ngZoneHelperService: NgZoneHelperService, @Optional() private transferState: TransferState) {}

  public getIconData$(icon: IconName): Observable<string> {
    const parsedIconName = icon.split(IconNameSeparator)
    const hashIcon = parsedIconName.pop()
    const iconName = parsedIconName.join(IconNameSeparator)

    const pathNameWithQuery = `${ICONS_ASSETS_PATH}/${iconName}.svg?v=${hashIcon || ''}`
    let url: string

    try {
      url = new URL(pathNameWithQuery, __webpack_public_path__ || this.location.origin).toString()
    } catch (e) {
      log.warn({ where: 'shared-icons: IconsService', category: 'try_catch', message: 'getIconData$ url failed', error: e, icon })
      url = `/${pathNameWithQuery}`
    }

    // use rehydration data from server
    const cacheKey: StateKey<string> = makeStateKey<string>(icon)
    const storedIcon = this.transferState?.get(cacheKey, null)

    if (storedIcon) {
      this.registry.set(icon, of(storedIcon))
    }

    if (this.registry.has(icon)) {
      return this.registry.get(icon) || of('')
    }

    const icon$ = this.getIconByUrl$(url)

    this.registry.set(icon, icon$)
    return icon$
  }

  private getIconByUrl$(url: string): Observable<string> {
    return this.http.get<string>(url, { responseType: 'text' as 'json' }).pipe(
      this.ngZoneHelperService.outsideNgZone(),
      take(1),
      shareReplay(1),
      catchError(() => of(''))
    )
  }
}
