import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { concatMap, delay, retryWhen, take } from 'rxjs/operators'
import { DetectPlatformService } from '@alliance/shared/utils'

@Injectable()
export class RetryInterceptorService implements HttpInterceptor {
  private readonly DELAY_TIME = 1000
  private readonly RETRY_ATTEMPTS = 3
  private readonly ALLOWED_REST_METHODS = ['GET']

  public constructor(private platform: DetectPlatformService) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      retryWhen(errors =>
        this.platform.isBrowser && this.ALLOWED_REST_METHODS.indexOf(req.method) > -1
          ? errors.pipe(
              delay(this.DELAY_TIME),
              take(this.RETRY_ATTEMPTS),
              concatMap(e => throwError(e))
            )
          : errors.pipe(concatMap(e => throwError(e)))
      )
    )
  }
}
