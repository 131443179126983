import { CountryCodesEnum, LanguageCodesEnum } from '@alliance/shared/models'
import { DetectPlatformService, detectRobot, IsNewRobotaDomainService, isObjectWithProperty } from '@alliance/shared/utils'
import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router'
import { NAVIGATOR } from '@ng-web-apis/common'
import { Observable } from 'rxjs'
import { TranslationService } from '../translation.service'

@Injectable({ providedIn: 'root' })
export class LanguageGuard implements CanActivate {
  public constructor(
    @Inject(NAVIGATOR) public readonly navigator: Navigator,
    private router: Router,
    private translationService: TranslationService,
    private platform: DetectPlatformService,
    private isNewRobotaDomainService: IsNewRobotaDomainService
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url = state.url === '/' ? '' : state.url
    if (this.isNewRobotaDomainService.isNewRobotaDomain()) {
      return this.setLanguageForNewDomainRobota(next, url)
    }

    return this.setLanguageForOldDomainRabota(next, url)
  }

  private setLanguageForOldDomainRabota(next: ActivatedRouteSnapshot, url: string): Observable<boolean> | Promise<boolean> | boolean {
    // if is robot or is server
    if (this.platform.isServer || (this.navigator.userAgent && detectRobot(this.navigator.userAgent))) {
      return this.setLanguageForServerOrBotOldDomain(next)
    }

    return this.handleParamsLangOldDomain(next, url)
  }

  private setLanguageForNewDomainRobota(next: ActivatedRouteSnapshot, url: string): Observable<boolean> | Promise<boolean> | boolean {
    // if is robot or is server
    if (this.platform.isServer || (this.navigator.userAgent && detectRobot(this.navigator.userAgent))) {
      return this.setLanguageForServerOrBotNewDomain(next)
    }

    return this.handleParamsLangNewDomain(next, url)
  }

  private setLanguageForServerOrBotNewDomain(next: ActivatedRouteSnapshot): boolean {
    if (this.getLangFromParams(next.params) === CountryCodesEnum.RU) {
      this.translationService.setActiveLang(LanguageCodesEnum.RU)
      return true
    }

    this.translationService.setActiveLang(LanguageCodesEnum.UK)
    return true
  }

  private setLanguageForServerOrBotOldDomain(next: ActivatedRouteSnapshot): boolean {
    if (this.getLangFromParams(next.params) === CountryCodesEnum.UA) {
      this.translationService.setActiveLang(LanguageCodesEnum.UK)
      return true
    }

    this.translationService.setActiveLang(LanguageCodesEnum.RU)
    return true
  }

  private handleParamsLangNewDomain(next: ActivatedRouteSnapshot, url: string): boolean | Promise<boolean> {
    const langFromParams = this.getLangFromParams(next.params)

    if (langFromParams === CountryCodesEnum.UA) {
      return this.setUaLangWithReplace(url)
    }

    const langFromSaved = this.translationService.getSavedLanguageSettings()

    if (langFromSaved === LanguageCodesEnum.UK && langFromParams === CountryCodesEnum.RU) {
      this.translationService.setActiveLang(LanguageCodesEnum.UK)
      return this.router.navigateByUrl(`${url.replace('/ru', '')}`)
    }

    if (langFromParams === CountryCodesEnum.RU) {
      this.translationService.setActiveLang(LanguageCodesEnum.RU)
      this.translationService.saveLangSettingsToStorage(LanguageCodesEnum.RU)
      return true
    }

    if (!langFromParams && langFromSaved === LanguageCodesEnum.RU) {
      this.translationService.setActiveLang(LanguageCodesEnum.RU)
      return this.router.navigateByUrl(`/ru${url}`)
    }

    return true
  }

  private handleParamsLangOldDomain(next: ActivatedRouteSnapshot, url: string): boolean | Promise<boolean> {
    const langFromParams = this.getLangFromParams(next.params)

    if (langFromParams === CountryCodesEnum.UA) {
      this.translationService.setActiveLang(LanguageCodesEnum.UK)
      this.translationService.saveLangSettingsToStorage(LanguageCodesEnum.UK)
      return true
    }

    /* if user has saved ru lang settings in cookies */
    if (!langFromParams && this.translationService.getSavedLanguageSettings() === LanguageCodesEnum.RU) {
      this.translationService.setActiveLang(LanguageCodesEnum.RU)
      return true
    }

    this.translationService.setActiveLang(LanguageCodesEnum.UK)
    this.translationService.saveLangSettingsToStorage(LanguageCodesEnum.UK)
    return this.router.navigateByUrl(`/ua${url}`)
  }

  private setUaLangWithReplace(url: string): Promise<boolean> {
    this.translationService.setActiveLang(LanguageCodesEnum.UK)
    this.translationService.saveLangSettingsToStorage(LanguageCodesEnum.UK)
    return this.router.navigateByUrl(`${url.replace('/ua', '')}`)
  }

  private getLangFromParams(params: Params): string | null {
    return isObjectWithProperty<{ lang: string | null }>(params, 'lang') ? params.lang : null
  }
}
