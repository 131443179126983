import { AuthService } from '@alliance/shared/auth/api'
import { Injectable } from '@angular/core'
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable()
export class EmployerDashboardGuard implements CanActivateChild {
  public constructor(private authService: AuthService, private router: Router) {}

  public canActivateChild(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const employerHomePageUrl = '/employer'
    const dashboardPageUrl = '/my'

    const isEmployerHomePage = state.url.includes(employerHomePageUrl)
    const isDashboardPage = state.url.includes(dashboardPageUrl)

    const queryParams = state.root.queryParams

    if (this.authService?.isEmployer && isEmployerHomePage) {
      return this.router.navigate([dashboardPageUrl], { queryParams })
    }

    if (!this.authService?.isEmployer && isDashboardPage) {
      return this.router.navigate([employerHomePageUrl], { queryParams })
    }

    return true
  }
}
