import { RxStateService } from '@alliance/shared/models'
import { Injectable, Injector, ɵcreateInjector as createInjector } from '@angular/core'
import { AuthService } from '@alliance/shared/auth/api'
import { MediaService } from '@alliance/shared/utils'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { combineLatest, from, Observable, of } from 'rxjs'
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { ChatAbTestService } from '@alliance/chat/domain'
import { ActivationEnd, Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class WidgetRenderService extends RxStateService<{
  isMobileScreen: boolean
  isEmployer: boolean
  isSeeker: boolean
  chatExperiment: boolean
  hideChat: boolean
}> {
  public readonly overlayConfig: OverlayConfig = new OverlayConfig({
    hasBackdrop: false,
    panelClass: ['santa-fixed', 'santa-right-0', 'santa-bottom-0']
  })
  private overlayRef: OverlayRef | undefined
  public constructor(
    private mediaService: MediaService,
    private authService: AuthService,
    private overlay: Overlay,
    private chatAbTestService: ChatAbTestService,
    private _injector: Injector,
    private router: Router
  ) {
    super()
    this.initState({
      isMobileScreen: this.mediaService.select('isMobileScreen'),
      isEmployer: this.authService.token$.pipe(map(token => !!token && this.authService.isEmployer)),
      isSeeker: this.authService.token$.pipe(map(token => !!token && this.authService.isSeeker)),
      chatExperiment: this.chatAbTestService.getChatExperiments(),
      hideChat: this.router.events.pipe(
        filter((e): e is ActivationEnd => e && e instanceof ActivationEnd && !!e.snapshot.component),
        map(event => !!event.snapshot.data['hideChat'])
      )
    })
  }

  public initChat(): void {
    this.hold(
      combineLatest([this.select('isMobileScreen'), this.select('isEmployer'), this.select('isSeeker'), this.select('chatExperiment'), this.select('hideChat')]).pipe(
        switchMap(([isMobileScreen, isEmployer, isSeeker, chatExperiment, hideChat]) => {
          if (!isMobileScreen && isEmployer && chatExperiment && !hideChat) {
            return this.renderEmployerWidget$()
          }
          if (!isMobileScreen && isSeeker && chatExperiment && !hideChat) {
            return this.renderSeekerWidget$()
          }
          if (!isMobileScreen && (isSeeker || isEmployer) && !chatExperiment) {
            return this.renderOldChatWidget$()
          }
          this.overlayRef?.detach()
          return of(null)
        })
      )
    )
  }

  public renderSeekerWidget$(): Observable<boolean> {
    return from(import('@alliance/chat/shells/seeker/desktop')).pipe(
      map(({ ChatWidgetSeekerComponent }) => {
        this.overlayRef = this.overlay.create(this.overlayConfig)
        const portal = new ComponentPortal(ChatWidgetSeekerComponent)
        this.overlayRef.attach(portal)
        return true
      }),
      catchError(() => of(false))
    )
  }

  public renderEmployerWidget$(): Observable<boolean> {
    return from(import('@alliance/chat/shells/employer/desktop')).pipe(
      map(({ ChatWidgetEmployerComponent }) => {
        this.overlayRef = this.overlay.create(this.overlayConfig)
        const portal = new ComponentPortal(ChatWidgetEmployerComponent)
        this.overlayRef.attach(portal)
        return true
      }),
      catchError(() => of(false))
    )
  }

  public renderOldChatWidget$(): Observable<boolean> {
    return from(import('@alliance/chat/shell-sidebar')).pipe(
      map(({ ChatShellSidebarModule }) => {
        const injector = createInjector(ChatShellSidebarModule, this._injector)
        const module = injector.get(ChatShellSidebarModule)
        module.dynamicLoadShell()
        return true
      }),
      catchError(() => of(false))
    )
  }

  public renderNotification(): void {
    this.hold(
      from(import('@alliance/chat/features/notification')).pipe(
        map(({ ChatNotificationComponent }) => {
          const overlayRef = this.overlay.create(this.overlayConfig)
          const portal = new ComponentPortal(ChatNotificationComponent)
          overlayRef.attach(portal)
          return true
        }),
        catchError(() => of(null))
      )
    )
  }
}
