import { ComponentRef, Injectable, TemplateRef, Type, ViewContainerRef } from '@angular/core'
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal'
import { asyncScheduler, timer } from 'rxjs'
import { ModalOverlayRef, ModalService } from '../modal'
import { MobileOverlayModalComponent } from '../../components/mobile-overlay-modal/mobile-overlay-modal.component'

@Injectable({
  providedIn: 'root'
})
export class MobileModalService extends ModalService {
  public override openByComponent<T, K>(content: Type<T>, data?: K, resizeOnContentChange = false, fullHeight = false): ModalOverlayRef {
    const overlayRef = this.createOverlay()
    const portal = new ComponentPortal(content)
    const modalRef = new ModalOverlayRef(overlayRef)

    const injector = this.createInjector<K | undefined>(modalRef, data)

    const modalPortal = new ComponentPortal<MobileOverlayModalComponent<T>>(MobileOverlayModalComponent, null, injector)

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<MobileOverlayModalComponent<T>> = overlayRef.attach(modalPortal)
      modalComponent.instance.content = portal
      modalComponent.instance.fullHeight = fullHeight
      modalComponent.instance.resetHeightOnContentMutation = resizeOnContentChange
      modalComponent.changeDetectorRef.detectChanges()
    })
    return modalRef
  }

  public override openByTemplate<K>(content: TemplateRef<K>, context?: K, viewContainerRef: ViewContainerRef | null = null, fullHeight = false): ModalOverlayRef {
    const overlayRef = this.createOverlay()
    const modalRef = new ModalOverlayRef(overlayRef)
    const modalPortal = new ComponentPortal<MobileOverlayModalComponent<K>>(MobileOverlayModalComponent, viewContainerRef, this.createInjector<K | undefined>(modalRef, context))

    timer(0, asyncScheduler).subscribe(() => {
      const modalComponent: ComponentRef<MobileOverlayModalComponent<K>> = overlayRef.attach(modalPortal)
      // В TemplatePortal - viewContainerRef является обязательным параметром, однако в некоторых случаях он может быть null. Поэтому я добавил метод в компонент, который возвращает ViewContainerRef.
      modalComponent.instance.content = new TemplatePortal(content, viewContainerRef ?? modalComponent.instance.getViewContainerRef(), context)
      modalComponent.instance.fullHeight = fullHeight
    })

    return modalRef
  }
}
