<alliance-notification-shell-alert-lazy *ngIf="isBrowser"></alliance-notification-shell-alert-lazy>

<app-shell-header [userSide]="state.userSide" [headerData]="state.headerData" [isEmployer]="state.isEmployer" [isSeeker]="state.isSeeker"></app-shell-header>

<div class="main-content-wrapper">
  <router-outlet (activate)="routerOnActivate()"></router-outlet>

  <alliance-shared-footer [userSide]="state.userSide" [footerData]="state.footerData" [isEmployer]="state.isEmployer" [isSeeker]="state.isSeeker"></alliance-shared-footer>
</div>

<app-mobile-navigation-bar [userSide]="state.userSide" [mobileBarData]="state.mobileBarData"></app-mobile-navigation-bar>

<app-ua-banner *ngIf="isBrowser" [mobileBarIsShow]="!!state.mobileBarData" [hideUaBanner]="state.hideUaBanner"></app-ua-banner>

<app-fast-google-auth *ngIf="isBrowser"></app-fast-google-auth>

<app-eng-widget *ngIf="isBrowser"></app-eng-widget>
