import { RUA_DOMAIN } from '../constants'

export default {
  home: {
    title: `Работа в Украине. Трудоустройство и поиск работы на ${RUA_DOMAIN}`,
    description: `Сайт поиска работы ${RUA_DOMAIN}. Удобные фильтры, качественная база вакансий и резюме - найди лучшую работу у нас!`
  },
  employer: {
    title: `Публикация вакансий — Размещение эффективных вакансий на сайте ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} - ★ Публикация эффективных вакансий ★ - Самая большая база профессиональных резюме Украины ➨ Умный поиск ➨ Лучшие варианты ➨ Найти сотрудника теперь просто! ${RUA_DOMAIN}`
  },
  vacancyList: {
    pageLeadingText: '| Стр. {page}',
    ukraine: 'Украине',
    h1: '{keywords, select, null{Работа} other{Работа {keywords}}} в {cityName}',
    default: {
      title: `Вакансии в Украине. Найти работу на ${RUA_DOMAIN}`,
      description: `На нашем сайте всегда самые свежие вакансии. Просматривайте список вакансий по разделам и городам`
    },
    onlyCity: {
      title: `▷ Работа в {cityName} | Поиск вакансий в {cityName} - найди свою работу на ${RUA_DOMAIN}`,
      description: `Ищете работу в {cityName}? ✔ Работа в {cityName} - {totalItems} актуальных вакансий в {cityName} для вас! ✌ Найти свою работу в {cityName} на ${RUA_DOMAIN}`
    },
    withKeywords: {
      title: `{keywords, select, null{Работа} other{Работа {keywords}}} в {cityName}, поиск {keywords, select, null{вакансий} other{вакансий {keywords}}} в {cityName} | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} ✪ поиск {keywords, select, null{работы} other{работы {keywords}}} в {cityName}. ➤ {totalItems} актуальных {keywords, select, null{вакансий} other{вакансий {keywords}}} в {cityName}. ☛ Лучшие вакансии`
    },
    withRubric: {
      title: `{rubricName}: вакансии в {cityName}, найдите работу на ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} ✪ поиск работы в сфере {rubricName} в {cityName}. ➤ {totalItems} актуальных вакансий в сфері {rubricName} в {cityName} ☛ Лучшие вакансии.`
    }
  },
  company: {
    title: `Работа в {companyName}. Вакансии в {companyName} | ${RUA_DOMAIN}`,
    description: `{vacanciesCount} {vacanciesCount, plural, one{вакансия} few{вакансии} other{вакансий}} {companyName} на ${RUA_DOMAIN}. Хотите работать в {companyName}. Только актуальные вакансии на ${RUA_DOMAIN}.`
  },
  vacancy: {
    title: `Вакансия: {vacancyName} {salary} в {cityInflected} - {companyName} | ${RUA_DOMAIN}`,
    description: `Вакансия - {vacancyName} в {cityInflected}, {companyName} ► {badges}.`,
    uah: 'грн'
  },
  prozora: {
    in: 'в',
    title: `Средняя зарплата{cityInflected}. Размер, статистика заработной платы на ${RUA_DOMAIN}`,
    description: `Какая средняя зарплата{keyword}{cityInflected}. Средний доход, уровень, статистика заработной платы на ${RUA_DOMAIN}`,
    ukraine: 'Украине',
    h1: {
      statistic: 'Статистика',
      forPosition: 'для позиции',
      in: 'по'
    }
  },
  cvwelcome: {
    title: `Создать резюме - составить резюме онлайн | Конструктор резюме от ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} - Создай резюме онлайн. Конструктор резюме от ${RUA_DOMAIN} поможет составить резюме онлайн бесплатно. Шаблон резюме с фото ${RUA_DOMAIN}`,
    h1: {
      publish: 'Разместить',
      resume: 'резюме'
    }
  },
  jsonLd: {
    breadcrumbs: {
      home: 'Главная',
      vacancies: 'Вакансии',
      resume: 'Резюме',
      resumeInUkraine: 'Резюме в областях Украины',
      job: 'Работа',
      inCity: 'в {cityInflected}',
      allUkraine: 'Вся Украина'
    },
    androidAppName: 'rabota.ua - работа в Украине (для соискателей)',
    androidEmployerAppName: `${RUA_DOMAIN} - работа в Украине`, // TEMPORARY TEXT
    iosAppName: `Работа и вакансии на ${RUA_DOMAIN}`,
    iosEmployerAppName: `Работа и вакансии на ${RUA_DOMAIN}` // TEMPORARY TEXT
  },
  searchByRootPage: {
    title: `Поиск резюме - Найти резюме сотрудника в Украине — Подбор персонала на ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} - ★ Поиск резюме ★ - Самая большая база профессиональных резюме Украины ➨ Умный поиск ➨ Лучшие варианты ➨ Найти сотрудника теперь просто! ${RUA_DOMAIN}`,
    defaultHeadingText: 'Поиск резюме ',
    h1: {
      rubrics: 'по рубрикам',
      professions: 'по профессиям',
      cities: 'по городам'
    }
  },
  searchByRubrics: {
    title: `Вакансии в Украине. Найти работу на ${RUA_DOMAIN}`,
    description: 'На нашем сайте всегда самые свежие вакансии. Просматривайте список вакансий по разделам и городам',
    h1: 'Поиск вакансий по рубрикам'
  },
  searchTabCity: {
    title: `Поиск работы по областям Украины - ${RUA_DOMAIN}`,
    description: `Удобный поиск работы по областям Украины ✔ Лучшие предложения работодателей ➨ Быстрый и удобный алгоритм поиска работы на сайте ${RUA_DOMAIN}`,
    h1: 'Поиск работы по городам Украины'
  },
  searchTabRegion: {
    title: `Поиск работы по областям Украины - ${RUA_DOMAIN}`,
    description: `Удобный поиск работы по областям Украины ✔ Лучшие предложения работодателей ➨ Быстрый и удобный алгоритм поиска работы на сайте ${RUA_DOMAIN}`,
    h1: 'Поиск работы по областям Украины'
  },
  searchProfAlphabet: {
    title: `Поиск вакансий по профессиям в Украине - ${RUA_DOMAIN}`,
    description: `Удобный поиск работы по профессиям в Украине. Статистика количества вакансий по каждой профессии в Украине на сайте ${RUA_DOMAIN}`,
    h1: 'Поиск вакансий по профессиям'
  },
  searchProfCity: {
    title: `Поиск работы по профессиям в городах Украины - ${RUA_DOMAIN}`,
    description: `Удобный поиск вакансий по профессиям во всех городах Украины на портале ${RUA_DOMAIN}`,
    h1: 'Поиск вакансий по профессиям в Украине'
  },
  searchProfSelectCity: {
    title: `Поиск работы по профессиям в {cityName} - ${RUA_DOMAIN}`,
    description: `Удобный поиск работы по специальности в {cityName}. Лучшие предложения работодателей ➨ Быстрый и удобный алгоритм поиска работы на сайте ${RUA_DOMAIN}`,
    h1: 'Поиск вакансий по профессиям в {cityName}'
  },
  searchCompanyIndustry: {
    title: `Поиск работы по компаниям Украины - ${RUA_DOMAIN}`,
    description: `Удобный поиск работы по компаниям Украины ✔ Лучшие предложения работодателей ➨ Быстрый и удобный алгоритм поиска работы на сайте ${RUA_DOMAIN}`,
    h1: 'Поиск работы по компаниям'
  },
  searchCompanyName: {
    pageLeadingText: '| Стр. {page}',
    default: {
      title: `Компании в Украине. Каталог компаний на ${RUA_DOMAIN}`,
      description: `Компании в Украине. Трудоустройство, вакансии и работа в Украине. Отраслевой каталог компаний на портале ${RUA_DOMAIN}`
    },
    h1: 'Поиск работы в компаниях'
  },
  candidates: {
    default: {
      pageLeadingText: '| Стр. {page}',
      title: `Резюме во Всей Украине, поиск резюме во Всей Украине | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} - поиск резюме во Всей Украине. {total} актуальных резюме во Всей Украине`,
      h1: 'Резюме во Всей Украине'
    },
    city: {
      title: `Резюме в {cityInflected}, поиск резюме в {cityInflected} | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} - поиск резюме в {cityInflected}. {total} актуальных резюме в {cityInflected}`,
      h1: 'Резюме в {cityInflected}'
    },
    keyword: {
      title: `Резюме {keyword} во Всей Украине, поиск резюме {keyword} во Всей Украине | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} - поиск резюме {keyword} во Всей Украине. {total} актуальных резюме {keyword} во Всей Украине`,
      h1: 'Резюме {keyword} в Украине'
    },
    keywordAndCity: {
      title: `Резюме {keyword} в {cityInflected}, поиск резюме {keyword} в {cityInflected} | ${RUA_DOMAIN}`,
      description: `${RUA_DOMAIN} - поиск резюме {keyword} в {cityInflected}. {total} актуальных резюме {keyword} в {cityInflected}`,
      h1: 'Резюме {keyword} в {cityInflected}'
    }
  },
  candidatePage: {
    resume: 'Резюме',
    in: 'в',
    jobSearch: 'поиск работы',
    keyInfo: 'Ключевая информация',
    lastJob: 'последнее место работы',
    unknown: 'неизвестно',
    years: '{amount} {amount, plural, one{год} few{года} other{лет}}',
    months: '{amount} {amount, plural, one{месяц} few{месяца} other{месяцев}}'
  },
  districtPathTest: {
    h1: 'Работа водителем в {district} районе Киева',
    title: 'Работа водителем в {district} районе Киева',
    desc: 'Ищете работу водитель в {district} районе Киева? ✪ {totalItems} актуальных вакансий для Вас! ☛ Найди свою работу на robota.ua'
  },
  metroPathTest: {
    h1: 'Работа водителем на метро {metro}',
    title: 'Работа водитель на метро {metro} | Поиск вакансий водитель Академгородок - найти работу на robota.ua',
    desc: 'Ищете работу водитель на метро {metro}? ✪ {totalItems} актуальных вакансий для Вас! ☛ Найди свою работу на robota.ua'
  }
}
