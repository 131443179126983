import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { DetectPlatformService } from '@alliance/shared/utils'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, take } from 'rxjs/operators'
import { AuthService } from '../authentication/auth.service'

@Injectable()
export class LogoutUnauthorizedUsersInterceptor implements HttpInterceptor {
  private readonly whitelist = [this.env.authApi].map(url => {
    try {
      return url ? new URL(url).origin : url
    } catch (e) {
      log.warn({ where: 'auth-api: LogoutUnauthorizedUsersInterceptor', category: 'try_catch', message: 'allowedUrls failed', error: e })
      return ''
    }
  })

  public constructor(private platform: DetectPlatformService, private authService: AuthService, private env: Environment) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let requestUrlOrigin = ''

    try {
      requestUrlOrigin = new URL(req.url).origin
    } catch (e) {
      log.log({ where: 'auth-api: LogoutUnauthorizedUsersInterceptor', category: 'try_catch', message: 'intercept: getting requestUrlOrigin failed', error: e })
    }

    return this.platform?.isBrowser && !this.whitelist.includes(requestUrlOrigin)
      ? next.handle(req).pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              this.authService
                .logout()
                .pipe(take(1))
                .subscribe(() => {
                  log.log({ where: 'auth-api: LogoutUnauthorizedUsersInterceptor', message: '401 -> logout' })
                })
            }
            return throwError(err)
          })
        )
      : next.handle(req)
  }
}
