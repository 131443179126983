import { RxStateComponent } from '@alliance/shared/models'
import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, Input, ViewContainerRef, ɵcreateInjector as createInjector } from '@angular/core'

@Component({
  selector: 'app-ua-banner',
  template: '',
  styleUrls: ['./shell.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellUaBannerComponent
  extends RxStateComponent<{
    mobileBarIsShow: boolean
    hideUaBanner: boolean
  }>
  implements AfterViewInit {
  public constructor(private _viewContainerRef: ViewContainerRef, private _injector: Injector) {
    super()
  }

  @Input()
  public set mobileBarIsShow(mobileBarIsShow: boolean) {
    this.set({ mobileBarIsShow })
  }

  @Input()
  public set hideUaBanner(hideUaBanner: boolean) {
    this.set({ hideUaBanner })
  }

  public ngAfterViewInit(): void {
    import('@alliance/shared/feature/ua-banner').then(({ SharedFeatureUaBannerModule }) => {
      const injector = createInjector(SharedFeatureUaBannerModule, this._injector)
      const module = injector.get(SharedFeatureUaBannerModule)
      const component = module.lazyRender(this._viewContainerRef)
      component.instance.connect('mobileBarIsShow', this.select('mobileBarIsShow'))
      component.instance.connect('hideUaBanner', this.select('hideUaBanner'))
    })
  }
}
