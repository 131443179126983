import { EmployerInterceptorsRestrictedByRightMiddlewareModule } from '@alliance/employer/interceptors/restricted-by-right-middleware'

import { SharedBrowserStorageModule } from '@alliance/shared/storage'
import { SharedChunkLoadErrorHandlerModule } from '@alliance/shared/chunk-load-error-handler'
import { SharedGqlAppendOperationNameInGqlUrlModule } from '@alliance/shared/gql/append-operation-name-in-gql-url'
import { SharedSentryModule } from '@alliance/shared/sentry'
import { LocalHammerConfig } from '@alliance/shared/utils'

import { BrowserTransferStateModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import 'hammerjs'

import { AppModule } from './app.module'
import { AppComponent } from './app.component'
import { APP_SENTRY_INIT_OPTIONS } from './configs/sentry-init-options'
import { ChatShellDesktopAppModule } from '@alliance/chat/shell-desktop-app'

@NgModule({
  imports: [
    // angular modules
    AppModule,
    BrowserTransferStateModule,

    // shared modules
    SharedBrowserStorageModule.forRoot(),
    SharedSentryModule.forRoot(APP_SENTRY_INIT_OPTIONS),
    SharedChunkLoadErrorHandlerModule.forRoot(), // relies on SharedSentryModule.forRoot()
    SharedGqlAppendOperationNameInGqlUrlModule,

    ChatShellDesktopAppModule,
    // other modules
    HammerModule,

    // employer modules
    EmployerInterceptorsRestrictedByRightMiddlewareModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: LocalHammerConfig
    }
  ]
})
export class AppBrowserModule {}
