import { AuthService as AuthRequestService, LoginModel } from '@alliance/shared/auth/data-access'
import { Environment } from '@alliance/shared/environment'
import { log } from '@alliance/shared/logger'
import { CookieStorage, LocalStorage } from '@alliance/shared/storage'
import { DetectPlatformService, GetTokenFromCookiesService, isJwtTokenExpired } from '@alliance/shared/utils'

import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { LOCATION } from '@ng-web-apis/common'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators'

import { AUTOLOGIN_QUERY_NAME, COOKIE_TOKEN_KEY, LOCAL_STORAGE_TOKEN_KEY } from './constants'
import { UserData } from './models'
import { decodeToken } from './utils'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token$: Observable<string | null>

  public navigateAfterLogout$ = new Subject<'login' | 'home'>()

  /**
   * @deprecated use token$ instead
   */
  public tokenChanged$: Observable<boolean>

  /**
   * @deprecated use token$ instead
   */
  public tokenAppeared$: Observable<boolean>

  /**
   * @deprecated check token instead
   */
  public isLoggedIn$: Observable<boolean>

  private _token$ = new BehaviorSubject<string | null>(this.token)

  public constructor(
    private authRequestService: AuthRequestService,
    private env: Environment,
    private cookieStorage: CookieStorage,
    private localStorage: LocalStorage,
    private platform: DetectPlatformService,
    private getTokenFromCookiesService: GetTokenFromCookiesService,
    @Inject(LOCATION) private readonly location: Location,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.token$ = this._token$.asObservable().pipe(
      distinctUntilChanged((tokenPrevious, tokenCurrent) => {
        const tokenIsSame = tokenPrevious === tokenCurrent
        const emailIsSame = decodeToken(tokenPrevious)?.email === decodeToken(tokenCurrent)?.email
        return tokenIsSame || emailIsSame
      })
    )

    this.tokenChanged$ = this._token$.asObservable().pipe(
      distinctUntilChanged((tokenPrevious, tokenCurrent) => {
        const tokenIsSame = tokenPrevious === tokenCurrent
        const emailIsSame = decodeToken(tokenPrevious)?.email === decodeToken(tokenCurrent)?.email
        return tokenIsSame || emailIsSame
      }),
      map(token => !!token)
    )

    this.tokenAppeared$ = this.tokenChanged$.pipe(filter(token => !!token))

    this.isLoggedIn$ = this._token$.pipe(map(token => !!token))
  }

  public get token(): string | null {
    /* temporarily condition, later we enable it for SSR */
    if (this.platform.isServer) {
      return null
    }

    try {
      /* temporary for admin-crm, need change the backend */
      if (this.env.app === 'admin-crm') {
        const windowObj = this.document?.defaultView as Window & typeof globalThis & { CRM: { Global: { CrmApiToken: string } } }
        return windowObj?.CRM?.Global?.CrmApiToken ?? null
      }

      return this.getTokenFromCookiesService.tokenFromCookie(COOKIE_TOKEN_KEY)
    } catch (e) {
      log.log({ where: 'auth-api: AuthService', category: 'try_catch', message: 'get token failed', error: e })
      return null
    }
  }

  public get user(): UserData | null {
    return decodeToken(this.token)
  }

  public get isEmployer(): boolean {
    const { IsEmployer = 0 } = this.user || {}
    return +IsEmployer === 1
  }

  public get isSeeker(): boolean {
    const { IsEmployer = 0 } = this.user || {}
    return +IsEmployer === 0
  }

  /**
   * @deprecated RUA-20829 for new employer features use employerRightsService.select('isMain') instead
   */
  public get isMainUser(): boolean {
    const { RoleId = 0 } = this.user || {}
    return +RoleId === 1
  }

  public isOwner(ownerId: string): boolean {
    return ownerId === this.user?.MultiUserId?.toString()
  }

  public getUserFromTokenChangesByCustomComparator(comparator: (previous: UserData | null, current: UserData | null) => boolean): Observable<UserData | null> {
    return this._token$.asObservable().pipe(
      map(token => decodeToken(token)),
      distinctUntilChanged(comparator)
    )
  }

  public syncToken(): void {
    this._token$.next(this.token)
  }

  public initializeApp(): Observable<boolean> {
    if (this.platform.isServer) {
      return of(true)
    }
    const urlParams = new URLSearchParams(this.location.search)
    const userHasParamsToAutologin = urlParams.has(AUTOLOGIN_QUERY_NAME)
    const userIsUnlogged = !this.token

    return this.migrationToken(userHasParamsToAutologin).pipe(
      switchMap(() => {
        /* autologin (from email for instance) */
        if (userHasParamsToAutologin && userIsUnlogged) {
          return this.autoLogin(urlParams.get(AUTOLOGIN_QUERY_NAME) || '').pipe(
            catchError(e => {
              log.error({ where: 'auth-api: AuthService', category: 'api_call_failed', message: 'autoLogin failed', error: e })
              return of(true)
            }),
            map(() => true)
          )
        }

        return of(true)
      }),
      map(() => true)
    )
  }

  public refresh(): Observable<string | null> {
    return this.authRequestService.authRefresh().pipe(
      take(1),
      catchError(e => {
        log.warn({ where: 'auth-api: AuthService', category: 'api_call_failed', message: 'authRefresh failed', error: e })
        return of(null)
      }),
      map(value => {
        this.syncToken()
        return value
      })
    )
  }

  public login(model: LoginModel): Observable<string> {
    const credentials: LoginModel = {
      username: model.username,
      password: model.password,
      remember: model.remember
    } as const

    return this.authRequestService.authLogin({ model: credentials }).pipe(
      take(1),
      map(value => {
        this.syncToken()
        return value
      })
    )
  }

  public logout(redirectPage: 'login' | 'home' = 'login'): Observable<boolean> {
    return this.authRequestService.authLogout().pipe(
      take(1),
      map(() => {
        this.navigateAfterLogout$.next(redirectPage)
        this.syncToken()
        this.cookieStorage.clearAllRemovableKeysAfterLogout()
        return true
      })
    )
  }

  public autoLogin(key: string): Observable<string | boolean> {
    return this.authRequestService.authAutoLogin({ request: { key } }).pipe(
      take(1),
      map(value => {
        this.syncToken()
        return value
      }),
      catchError(e => {
        log.warn({ where: 'auth-api: AuthService', category: 'api_call_failed', message: 'authAutoLogin failed', error: e })
        return of(true)
      })
    )
  }

  // temporary, todo: remove after November of 2021
  private migrationToken(userHasParamsToAutologin = false): Observable<boolean> {
    // temp for admin-crm
    if (this.env.app === 'admin-crm') {
      return of(true)
    }

    try {
      const oldTokenInLS = this.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
      this.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)

      if (!userHasParamsToAutologin && oldTokenInLS && !isJwtTokenExpired(oldTokenInLS)) {
        return this.refresh().pipe(map(() => true))
      }
    } catch (e) {
      log.warn({ where: 'auth-api: AuthService', category: 'try_catch', message: 'migrationToken failed', error: e })
    }

    return of(true)
  }
}
